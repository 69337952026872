import { GenexSISModel } from "@/models/sis/GenexSISModel";
import { format } from "date-fns";
import LearningStream from "@/models/sis/LearningStream";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class Tenant extends GenexSISModel {
  static entity = "tenants";

  get formatted_date() {
    return format(new Date(this.created_at), localStorage.getItem("dateFormat") ?? "yyyy-MM-dd" + " hh:mm");
  }

  static fields() {
    return {
      id: this.attr(null),
      active: this.attr(null),
      school_name: this.attr(""),
      is_demo: this.attr(0),
      is_white_labeled: this.attr(0),
      contact_person_id: this.attr(null),
      website: this.attr(null),
      student_count: this.attr(null),
      contact_number: this.attr(null),
      curricula: this.hasManyBy(LearningStream, "curriculum_ids"),
      curriculum_ids: this.attr([]),
      email: this.attr(null),
      timezone: this.attr(null),
      country: this.attr(null),
      created_at: this.attr(null),
      uuid: this.attr(null),
      slug: this.attr(null),
      lms_config: this.attr(null),
      storage: this.attr({
        key: this.attr(null),
        bucket: this.attr(null),
        driver: this.attr(null),
        region: this.attr(null),
        secret: this.attr(null),
      }),
      currency: this.attr(null),
      whitelabel_urls: this.attr(0),
      sis_url: this.attr(null),
      learn_url: this.attr(null),
      whitelabel_emails: this.attr(0),
      mailgun_from_address: this.attr(null),
      mailgun_domain: this.attr(null),
      mailgun_username: this.attr(null),
      mailgun_password: this.attr(null),
      mailgun_key: this.attr(null),
    };
  }

  /**
   * Returns a tenant
   * @function
   * @param {number} tenant_id - The id of the tenant
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(tenant_id, relationships = []) {
    return this.api().get(`/tenants/${tenant_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,

      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Returns all tenants
   * @function
   * @param {?Object} pagination
   * @param {?number} pagination.page - Which page to retrieve
   * @param {?number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {string} [query.search]
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 999 }, query = {}) {
    return this.api().get(`/tenants`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,
      params: {
        ...{
          page: page,
          limit: limit,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(tenant => {
          return { ...tenant, ...tenant.attributes };
        });
      },
    });
  }

  /**
   * Store a new tenant
   * @function
   * @param {Object} tenant - The tenant object
   * @returns {Promise<Response>} - The newly created assessment
   */

  static Store(tenant) {
    return this.api().post(`/tenants`, tenant, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,

      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a tenant
   * @function
   * @param {Object} tenant - The term object
   * @returns {Promise<Response>} - The updated term
   */

  static Update(tenant) {
    return this.api().patch(`/tenants/${tenant.id}`, tenant, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,

      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a tenant
   * @function
   * @param {number} id - The id of the tenant
   */

  static Delete(id) {
    return this.api().delete(`/tenants/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
