<template>
  <card-component icon="document-upload" :title="title" un-card>
    <template v-if="hasUpload && canCreate">
      <MediaPanelDropZone
        v-model="dropFiles"
        :fileLimit="fileLimit"
        :multiple="multiple"
        :message="message"
        :replaceDropzoneWithButton="replaceDropzoneWithButton"
        :fileAggrigateTypes="fileAggrigateTypes"
        :media_count="media_count"
        :disabled="disabled" />
      <div class="columns is-multiline is-fullwidth">
        <MediaPanelForm
          v-model="dropFiles"
          :fieldDisableCaption="fieldDisableCaption"
          :hasTag="hasTag"
          :showGuardianVisibility="showGuardianVisibility"
          :canViewSensitiveMedia="canViewSensitiveMedia"
          :submit_button="submit_button"
          :model_id="model_id"
          :model="model"
          :mediable_type="mediable_type"
          :tags="tags"
          :default_tag="default_tag"
          :updatingMedia="updatingMedia"
          @setUpdatingMedia="setUpdatingMedia"
          @submitted="$emit('submitted')"
          :isCentral="isCentral"
          :submit_flip="submit_flip" />
      </div>
    </template>

    <MediaPanelIndex
      v-if="hasTable"
      :canEdit="canEdit"
      :hasTag="hasTag"
      :tags="tags"
      :fieldDisableCaption="fieldDisableCaption"
      :fieldDisableExtension="fieldDisableExtension"
      :showGuardianVisibility="showGuardianVisibility"
      :canViewSensitiveMedia="canViewSensitiveMedia"
      :canDelete="canDelete"
      :fetchOnMount="fetchOnMount"
      @setUpdatingMedia="setUpdatingMedia"
      @setSelectedTag="setSelectedTag"
      :dropFiles="dropFiles"
      :model_id="model_id"
      :mediable_type="mediable_type"
      :model="model"
      :isCentral="isCentral"
      :fieldEnableSize="fieldEnableSize"
      :fieldEnableUpdateDate="fieldEnableUpdateDate"
      :fieldEnableCreationDate="fieldEnableCreationDate">
      <template #default="defaultProps">
        <slot :entity="defaultProps.entity"></slot>
      </template>
    </MediaPanelIndex>

    <MediaBubbles
      v-if="hasFileBubbles"
      :fetchOnMount="fetchOnMount"
      :model="model"
      :model_id="model_id"
      :mediable_type="mediable_type"
      :selectedTag="selectedTag"
      :can-edit="canEdit"
      :can-delete="canDelete"
      :isCentral="isCentral" />
    <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false" />
  </card-component>
</template>
<script>
  import CardComponent from "@/components/layout/CardComponent";
  import { format } from "date-fns";

  import MediaPanelIndex from "./MediaPanelIndex.vue";
  import MediaPanelForm from "./MediaPanelForm.vue";
  import MediaPanelDropZone from "./MediaPanelDropZone.vue";
  import Media from "@/models/sis/Media";
  import MediaBubbles from "@/components/media/MediaBubbles.vue";

  const mediaPanelTags = [
    "Birth Certificate",
    "Proof of Address",
    "Proof of Banking",
    "ID",
    "Passport",
    "School Report",
    "Academic Assessment",
    "Medical Certificate",
    "PoP (Registration)",
    "PoP (Enrolment)",
    "Entrance Assessment",
  ];

  export default {
    name: "MediaPanel",

    components: {
      MediaBubbles,
      MediaPanelIndex,
      CardComponent,
      MediaPanelForm,
      MediaPanelDropZone,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      hasFileBubbles: {
        type: Boolean,
        default: false,
      },
      isCentral: {
        type: Boolean,
        default: false,
      },
      programmaticFile: {
        type: Blob,
        default: null,
      },
      showGuardianVisibility: {
        type: Boolean,
        default: false,
      },
      submit_flip: {
        type: Boolean,
        default: false,
      },
      fetchOnMount: {
        type: Boolean,
        default: true,
      },
      submit_button: {
        type: Boolean,
        default: true,
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      fileLimit: {
        type: Number,
        default: null,
      },
      default_tag: {
        type: String,
        default: null,
      },
      model: {
        type: String,
        required: true,
      },
      mediable_type: {
        type: String,
        required: false,
      },
      message: {
        type: String,
        required: false,
      },
      model_id: {
        type: Number,
        required: true,
      },
      tags: {
        type: Array,
        default: () => mediaPanelTags,
      },
      canDelete: {
        type: Boolean,
        default: false,
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
      canEdit: {
        type: Boolean,
        default: false,
      },
      hasUpload: {
        type: Boolean,
        default: true,
      },
      hasTable: {
        type: Boolean,
        default: true,
      },
      hasTag: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: "",
      },
      replaceDropzoneWithButton: {
        type: Boolean,
        required: false,
      },
      fieldEnableIsSensitive: {
        type: Boolean,
        required: false,
      },
      fieldDisableCaption: {
        type: Boolean,
        required: false,
      },
      fieldDisableExtension: {
        type: Boolean,
        required: false,
      },
      fieldEnableSize: {
        type: Boolean,
        required: false,
      },
      fieldEnableUpdateDate: {
        type: Boolean,
        required: false,
      },
      fieldEnableCreationDate: {
        type: Boolean,
        required: false,
      },
      fileAggrigateTypes: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        updatingMedia: false,
        dropFiles: [],
        selectedTag: null,
      };
    },
    watch: {
      programmaticFile(newVal) {
        if (newVal !== null) {
          this.dropFiles.push(
            new File([newVal], `${this.$store.state.user.id}-${format(new Date(), this.dateFormat + "'_'hh:mm")}`),
          );
        }
      },
    },
    computed: {
      dateFormat() {
        return localStorage.getItem("dateFormat") ?? "yyyy-MM-dd";
      },
      media_count() {
        return Media.query()
          .where("mediable_type", this.model)
          .where("mediable_id", this.model_id)
          .where(media => {
            if (!this.selectedTag) {
              return true;
            }
            return this.selectedTag === media.tag;
          })
          .count();
      },
      canViewSensitiveMedia() {
        return this.hasPermission(["view sensitive media"]) && this.fieldEnableIsSensitive;
      },
    },
    mounted() {
      if (this.programmaticFile !== null) {
        this.dropFiles.push(
          new File(
            [this.programmaticFile],
            `${this.$store.state.user.id}-${format(new Date(), this.dateFormat + "'_'hh:mm")}`,
          ),
        );
      }
    },
    methods: {
      setUpdatingMedia(value) {
        this.updatingMedia = value;
      },
      setSelectedTag(value) {
        this.selectedTag = value;
      },
    },
  };
</script>
