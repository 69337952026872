<template>
  <div class="listitem">
    <article class="media">
      <figure v-if="showTypes" class="media-left">
        <p class="has-text-centered">
          <b-tooltip :label="type.name" append-to-body position="is-top">
            <b-icon :icon="type.icon" class="icon rounded is-large box is-64x64"></b-icon>
          </b-tooltip>
        </p>
      </figure>
      <div
        :class="{
          'ml-6': conversationMode && isOwnNote,
          'mr-6': conversationMode && !isOwnNote,
        }"
        class="box media-content is-relative">
        <div class="p-3">
          <b-dropdown
            v-if="permissions.can_delete || permissions.can_edit"
            :position="'is-top-left'"
            append-to-body
            aria-role="list"
            class="listitem-dropdown">
            <b-button slot="trigger" size="" icon-right="more-vertical" class="is-primary has-text-white"></b-button>

            <b-dropdown-item aria-role="listitem" @click="editNote(note)">Edit</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="startDelete(note)">Delete</b-dropdown-item>
          </b-dropdown>
          <p class="subtitle is-7">
            created by
            <span v-if="note.user">{{ note.user.first_name }} {{ note.user.last_name }}</span
            ><span v-else>System</span>
            @
            {{ formattedDate(new Date(note.created_at)) }}
            <span v-if="note.created_at !== note.updated_at"
              >edited @ {{ formattedDate(new Date(note.updated_at)) }}</span
            >
          </p>

          <div v-if="!noteBeingEdited" class="ck-content" v-html="note.note"></div>
          <div v-if="noteBeingEdited">
            <b-field>
              <GenexMathEditor
                :model-id="note?.id"
                :model="model_type"
                v-model="
                  // eslint-disable-next-line
                  note.note
                "
                placeholder="Note Body" />
            </b-field>
            <b-field>
              <b-button type="is-primary" @click="saveEdit">Save</b-button>
            </b-field>
          </div>
        </div>
      </div>
    </article>

    <hr />
  </div>
</template>

<script>
  import Note from "@/models/sis/Note";
  import GenexMathEditor from "@/components/froala/GenexMathEditor.vue";
  import { format } from "date-fns";

  export default {
    name: "NoteListItem",
    components: { GenexMathEditor },
    props: {
      conversationMode: {
        type: Boolean,
        default() {
          return false;
        },
      },
      isCentral: {
        type: Boolean,
        default() {
          return false;
        },
      },
      showTypes: {
        type: Boolean,
        default() {
          return true;
        },
      },
      model_type: { type: String, required: true },
      model_id: { type: Number, required: true },
      permissions: {
        type: Object,
        default: () => ({
          can_delete: false,
          can_create: false,
          can_edit: false,
        }),
      },

      note: {
        type: Object,
        default: () => Object,
      },
    },
    data() {
      return {
        noteBeingEdited: false,
        loadingNote: false,
      };
    },
    computed: {
      dateFormat() {
        return localStorage.getItem("dateFormat") ?? "yyyy-MM-dd";
      },
      isOwnNote() {
        if (this.note.user) {
          return this.note.user === this.$store.state.user.id;
        }
        return false;
      },
      type() {
        return { name: "Note", icon: "note" };
      },
    },
    methods: {
      formattedDate(date) {
        return format(date, this.dateFormat + " h:mm:ss a");
      },
      saveEdit() {
        this.loadingNote = true;

        Note.Update(this.note, { type: this.model_type, id: this.model_id }, this.isCentral)
          .then(() => {
            this.loadingNote = false;
            this.$buefy.snackbar.open({
              message: "Note edited",
              queue: false,
              type: "is-primary",
            });
          })
          .catch(err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          });
        this.noteBeingEdited = false;
      },
      editNote(note) {
        if (this.permissions.can_edit || note.user_id === this.$store.state.user.id) {
          return (this.noteBeingEdited = true);
        }
        this.$store.dispatch("toast/createToast");
      },
      deleteNote(note) {
        this.$store.dispatch("loader/show");
        Note.Delete(note.id, { type: this.model_type, id: this.model_id }, this.isCentral)
          .then(() => {
            this.loadingNote = false;
            this.$buefy.snackbar.open({
              message: "Note deleted",
              queue: false,
              type: "is-danger",
            });
            this.$store.dispatch("loader/hide");
          })
          .catch(err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          });
      },
      startDelete(note) {
        if (this.permissions.can_delete || note.user_id === this.$store.state.user.id) {
          this.$buefy.dialog.confirm({
            title: "Deleting note",
            message: "Are you sure you want to <b>delete</b> this note? This action cannot be undone.",
            confirmText: "Delete Note",
            type: "is-danger",
            hasIcon: true,
            onConfirm: () => this.deleteNote(note),
          });
          return;
        }
        this.$store.dispatch("toast/createToast");
      },
    },
  };
</script>
