import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import InviteUser from "@/models/sis/InviteUser";
import { format } from "date-fns";
import User from "@/models/sis/User";
// import Unit from "@/models/Unit";
// import UnitObjective from "@/models/UnitObjective";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An invite
 * @property {number} id
 */
export default class Invite extends GenexSISModel {
  static entity = "invites";

  get formatted_expires_at() {
    return format(new Date(this.expires_at), localStorage.getItem("dateFormat") ?? "yyyy-MM-dd" + " hh:mm");
  }

  get formatted_created_date() {
    return format(new Date(this.created_at), localStorage.getItem("dateFormat") ?? "yyyy-MM-dd" + " hh:mm");
  }

  get formatted_updated_date() {
    return format(new Date(this.updated_at), localStorage.getItem("dateFormat") ?? "yyyy-MM-dd" + " hh:mm");
  }

  static fields() {
    return {
      id: this.attr(null),
      expires_at: this.attr(null),
      is_active: this.attr(null),
      campus_id: this.attr(null),
      invite_users: this.hasMany(InviteUser, "invite_id"),
      campus: this.belongsTo(Campus, "campus_id"),
      key: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      creator_id: this.attr(null),
      user: this.belongsTo(User, "creator_id"),
    };
  }

  /**
   * Returns all Invite Users
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} query
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the CourseScopeTopics
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/invites`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(object => {
          if (relationships.includes("invite_users")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "invite_users")) {
              object.attributes.invite_users.map(user => {
                Object.assign(user, user.attributes);
              });
            }
          }
          if (relationships.includes("user")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "user")) {
              if (object.attributes.user !== null) {
                Object.assign(object.attributes.user, object.attributes.user.attributes);
              }
            }
          }
          if (relationships.includes("role")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "role")) {
              if (object.attributes.role !== null) {
                Object.assign(object.attributes.role, object.attributes.role.attributes);
              }
            }
          }
          if (relationships.includes("invite")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "invite")) {
              if (object.attributes.invite !== null) {
                Object.assign(object.attributes.invite, object.attributes.invite.attributes);
              }
            }
          }

          return { ...object, ...object.attributes };
        });
      },
    });
  }

  /**
   * Returns an Invite
   * @function
   * @param {number} id - The id of the Invite User
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/invites/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("invite_users")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "invite_users")) {
            data.attributes.invite_users.map(user => {
              Object.assign(user, user.attributes);
            });
          }
        }
        if (relationships.includes("role")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "role")) {
            if (data.attributes.role !== null) {
              Object.assign(data.attributes.role, data.attributes.role.attributes);
            }
          }
        }
        if (relationships.includes("user")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "user")) {
            if (data.attributes.user !== null) {
              Object.assign(data.attributes.user, data.attributes.user.attributes);
            }
          }
        }
        if (relationships.includes("invite")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "invite")) {
            if (data.attributes.invite !== null) {
              Object.assign(data.attributes.invite, data.attributes.invite.attributes);
            }
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new invite
   * @function
   * @param {Object} invite - The invite object
   * @returns {Promise<Response>} - The newly created invite
   */

  static Store(invite) {
    return this.api().post(`/invites`, invite, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Resend invite
   * @function
   * @param {Object} user - The user
   * @param {Number} tenantId - The tenant
   * @returns {Promise<Response>} - The newly created invite
   */

  static Accept(user, tenantId) {
    return this.api().post(`/invites/validate`, user, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      headers: { "X-Tenant": tenantId },

      saved: false,
    });
  }

  /**
   * Resend invite
   * @function
   * @param {Object} invite_id - The invite
   * @param {Array} emails - The emails to resend to
   * @returns {Promise<Response>} - The newly created invite
   */

  static Resend(invite_id, emails) {
    return this.api().post(
      `/invites/${invite_id}/resend`,
      { emails: emails },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        saved: false,
      },
    );
  }

  /**
   * Update a invite
   * @function
   * @param {Object} invite - The invite object
   * @returns {Promise<Response>} - The updated invite
   */

  static Update(invite) {
    return this.api().patch(`/invites/${invite.id}`, invite, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an invite
   * @function
   * @param {number} id - The id of the invite
   */

  static Delete(id) {
    return this.api().delete(`/invites/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
